import { Link } from "gatsby"
import React, { useEffect, useRef } from "react"
import { gsap, ScrollTrigger, Observer } from "wildGsap"
import { useThemeOptions } from "wildHooks/useThemeOptions"

import { cardData } from "./cardData"
import {
  Box,
  Button,
  Center,
  Container,
  Divider,
  Heading,
  Stack,
  Text,
  useTheme,
  VStack,
} from "@chakra-ui/react"

function Cards() {
  const scrollSection = useRef()
  const pinSection = useRef()
  const scrollAnimation = useRef(null)
  const { sidebarMenuMargin } = useThemeOptions()
  const { variables } = useTheme()
  const {
    sectionPaddingX,
    sectionPaddingY,
    componentSpacing,
    containerPaddingY,
  } = variables

  useEffect(() => {
    if (scrollAnimation.current || !ScrollTrigger || !scrollSection.current)
      return
    ScrollTrigger.matchMedia({
      "(min-width: 768px)": function () {
        const sectionWidth =
          scrollSection.current?.getBoundingClientRect().width
        const tl = gsap.timeline({
          paused: true,
          scrollTrigger: {
            trigger: pinSection.current,
            start: "top top",
            end: "",
            pin: true,
            id: "cards",
            toggleActions: "start none none restart",
            scrub: 0.5,
            anticipatePin: true,
          },
        })
        tl.to(scrollSection.current, {
          x: 0 - sectionWidth + window.innerWidth,
          ease: "none",
        })
        scrollAnimation.current = tl
      },
    })

    const cardsObserver = Observer.create({
      target: window,
      type: "wheel,scroll",
      onChangeX: ({ deltaX }) => {
        let y = window.pageYOffset
        window.scrollTo(0, y + deltaX * 0.5)
      },
    })

    return () => {
      if (scrollAnimation.current) scrollAnimation.current.kill()
      if (cardsObserver) cardsObserver.kill()
    }
  }, [])

  return (
    <Center
      as="section"
      h={["auto", "auto", "100vh"]}
      width={["100vw", "100vw", `calc(100vw - ${sidebarMenuMargin}px)`]}
      position="relative"
      ml={[0]}
      pt={0}
      py={sectionPaddingY}
      ref={pinSection}
    >
      <Container h="full" py={containerPaddingY} bg="dark.default">
        <VStack
          align="flex-start"
          position={["relative", "relative", "absolute"]}
          left="0"
          top="0"
          bottom="0"
          px="0"
          bg="dark.default"
          ref={scrollSection}
        >
          <Stack
            direction={["column", "column", "row"]}
            align="center"
            justify={["center", "center", "flex-start"]}
            spacing={[16, 16, 8]}
            px={[0, 0, "200px"]}
            height="full"
          >
            <VStack
              width={["100%", "100%", "40vw"]}
              spacing={componentSpacing}
              mx={sectionPaddingX}
            >
              <Heading className="jumbo" color="white" width="100%">
                <span>
                  <Text as="span" color="brandYellow.default">
                    Freedom
                  </Text>{" "}
                  to
                </span>{" "}
                shape your business{" "}
              </Heading>
              <Heading as="h3" className="thin-h3" color="white">
                Explore a range of business solutions tailored to give you the
                most competitive edge and value for money.
              </Heading>
            </VStack>
            {cardData.map((card, i) => {
              return (
                <VStack
                  h={["60vh", "60vh", "500px"]}
                  w={["90vw", "90vw", "500px"]}
                  rounded="30px"
                  bg="white"
                  key={card.title}
                  p={12}
                  spacing={4}
                >
                  <Heading as="h3" className="lower-case">
                    {card.title}
                  </Heading>
                  <Heading
                    as="h4"
                    className="lower-case"
                    sx={{ "&.lower-case": { textTransform: "unset" } }}
                    color="brandBlue.default"
                  >
                    {card.price}
                  </Heading>
                  <Divider />
                  <Text
                    color="brandYellow.default"
                    fontSize="18px"
                    fontWeight="extrabold"
                  >
                    What you'll get
                  </Text>
                  <Box
                    as="ul"
                    sx={{
                      "li.thin-h3": {
                        listStyle: "none",
                        listStylePosition: "inside",
                      },
                    }}
                  >
                    {card.contents.map((item, i) => {
                      return (
                        <Heading
                          as="li"
                          className="thin-h3"
                          textAlign="center"
                          key={item}
                        >
                          {item}
                        </Heading>
                      )
                    })}
                  </Box>
                  <Link to="/contact-us#form">
                    <Button>Buy Now</Button>
                  </Link>
                </VStack>
              )
            })}
            <VStack
              spacing={8}
              align={["center", "center", "start"]}
              width={["100%", "100%", "50vw"]}
              px={[8, 8, "10vw"]}
            >
              <Heading
                as="h4"
                textStyle="h4"
                fontSize="16px"
                fontWeight="semibold"
                textAlign={["center", "center", "left"]}
                color="white"
              >
                Looking for the ultimate free zone?
              </Heading>
              <Heading
                color="brandYellow.default"
                className="jumbo"
                textAlign={["center", "center", "left"]}
              >
                Let's do this
              </Heading>
              <Link to="/contact-us/">
                <Button>Start now</Button>
              </Link>
            </VStack>
          </Stack>
        </VStack>
      </Container>
    </Center>
  )
}

export default Cards
